import axios from 'axios';

// Base API URL for WordPress and Rank Math
const BASE_URL = 'https://cms.arrify.com/wp-json';

const SF_BASE_URL = `${BASE_URL}/arrify-one/sf-v1`;
const SF_CMS_URL = `${BASE_URL}/arrify-one/cms-v1`;

//get e-sign contract data
export const getEsignContractData = async (endpointUrl) => {
    console.log('get endpointUrl : ' + endpointUrl);
    try {
        const response = await axios.get(`${SF_CMS_URL}${endpointUrl}`);
        return response.data;
    } catch (error) {
        console.error('Failed to get Esign Contract Data :', error);
        throw error;
    }
};

//get Survey data
export const getSurveyData = async (endpointUrl) => {
    console.log('get endpointUrl : ' + endpointUrl);
    try {
        const response = await axios.get(`${SF_CMS_URL}${endpointUrl}`);
        return response.data;
    } catch (error) {
        console.error('Failed to get Survey Data :', error);
        throw error;
    }
};

// New function to submit chat data to Salesforce
export const submitChatData = async (data) => {
    try {
        const response = await axios.post(`${SF_CMS_URL}/LeadChat`, {
            userName: data.userName,
            userEmail: data.userEmail,
            conversation: data.conversation
        });
        return response.data;
    } catch (error) {
        console.error('Failed to submit chat data:', error);
        throw error;
    }
};

// New function to update chat data in Salesforce
export const updateChatData = async (chatId, data) => {
    try {
        const response = await axios.patch(`${SF_CMS_URL}/LeadChat/${chatId}`, {
            userName: data.userName,
            userEmail: data.userEmail,
            conversation: data.conversation
        });
        return response.data;
    } catch (error) {
        console.error('Failed to update chat data:', error);
        throw error;
    }
};

//post e-sign contract data
export const postEsignContractData = async (endpointUrl, data) => {
    console.log('post endpointUrl : ' + endpointUrl);
    try {
        const response = await axios.post(`${SF_CMS_URL}${endpointUrl}`, {
           ...data
        });
        return response.data;
    } catch (error) {
        console.error('Failed to post Esign Contract Data :', error);
        throw error;
    }
};

//post survey data
export const postSurveyData = async (endpointUrl, data) => {
    console.log('post endpointUrl : ' + endpointUrl);
    try {
        const response = await axios.post(`${SF_CMS_URL}${endpointUrl}`, {
           ...data
        });
        return response.data;
    } catch (error) {
        console.error('Failed to post seuvey Data :', error);
        throw error;
    }
};

// Precompile regular expressions for better performance
const URL_PATTERNS = {
    imageUrl: /https:\/\/cms\.arrify\.com\/wp-content\/uploads\//g,
    cmsUrl: /https:\/\/cms\.arrify\.com\//g,
    metaTag: /<meta\s+([^>]*?)>/g,
    linkTag: /<link\s+([^>]*?)>/g,
    scriptTag: /<script\s*([^>]*?)>([\s\S]*?)<\/script>/g,
    attributes: /(\w+)=["']([^"']*?)["']/g
};

// Replacement strings as constants
const REPLACEMENTS = {
    imageUrl: 'https://cdn.arrify.com/wp-content/uploads/',
    cmsUrl: 'https://arrify.com/'
};

// Memoization cache for transformed URLs
const urlCache = new Map();
const dataCache = new Map();

// Optimized URL transformer with caching
const transformUrl = (url) => {
    if (!url || typeof url !== 'string') return url;

    // Check cache first
    if (urlCache.has(url)) {
        return urlCache.get(url);
    }

    // Apply transformations only if needed
    let transformed = url;
    if (url.includes('cms.arrify.com')) {
        if (url.includes('/wp-content/uploads/')) {
            transformed = url.replace(URL_PATTERNS.imageUrl, REPLACEMENTS.imageUrl);
        } else {
            transformed = url.replace(URL_PATTERNS.cmsUrl, REPLACEMENTS.cmsUrl);
        }

        // Cache the result
        urlCache.set(url, transformed);
    }

    return transformed;
};
// Optimized content transformer
const transformContent = (content) => {
    if (!content || typeof content !== 'string') return content;
    if (!content.includes('cms.arrify.com')) return content;

    return content
        .replace(URL_PATTERNS.imageUrl, REPLACEMENTS.imageUrl)
        .replace(URL_PATTERNS.cmsUrl, REPLACEMENTS.cmsUrl);
};

// Optimized attribute parser
const parseAttributes = (attrString) => {
    const attrs = {};
    let match;

    while ((match = URL_PATTERNS.attributes.exec(attrString)) !== null) {
        const [, key, value] = match;
        attrs[key] = value.includes('cms.arrify.com') ? transformUrl(value) : value;
    }

    return attrs;
};
const parseMetaTags = (htmlString) => {
    if (!htmlString || !htmlString.includes('cms.arrify.com')) {
        return {meta: [], links: [], scripts: []};
    }

    const meta = [];
    const links = [];
    const scripts = [];

    // Parse meta tags
    htmlString.replace(URL_PATTERNS.metaTag, (_, attrString) => {
        const attrs = parseAttributes(attrString);
        if (Object.keys(attrs).length > 0) {
            meta.push(attrs);
        }
    });

    // Parse link tags
    htmlString.replace(URL_PATTERNS.linkTag, (_, attrString) => {
        const attrs = parseAttributes(attrString);
        if (Object.keys(attrs).length > 0) {
            links.push(attrs);
        }
    });

    // Parse script tags
    htmlString.replace(URL_PATTERNS.scriptTag, (_, attrString, content) => {
        const attrs = parseAttributes(attrString);
        if (content) {
            attrs.innerHTML = transformContent(content);
        }

        if (Object.keys(attrs).length > 0) {
            scripts.push(attrs);
        }
    });

    return {meta, links, scripts};
};

// Function to fetch data from local JSON file
const getLocalData = async (slug) => {
    try {
        // Using dynamic import for JSON files
        const data = await import(`~/wp-data/data/${slug}.json`);
        return data.default;
    } catch (error) {
        console.warn('Error loading local JSON:', error);
        return null;
    }
};


const transformPost = (post) => ({
    ...post,
    content: {
        ...post.content,
        rendered: transformContent(post.content.rendered)
    },
    excerpt: {
        ...post.excerpt,
        rendered: transformContent(post.excerpt.rendered)
    }
});

// Fetch post data from local JSON or WordPress API
export const getPost = async (slug) => {
    /*const localData = await getLocalData(slug);
    if (localData && localData.post) {
        console.log('Using local JSON data for post');
        return { data: [localData.post] };
    }

    console.log('Local data not found, fetching from API');*/
    try {
        // Try to get local data first
        const localData = await getLocalData(slug);
        if (localData && localData.post) {
            return { data: [transformPost(localData.post)] };
        }

        // Add specific fields to retrieve using _fields parameter
        const response = await axios.get(
            `${BASE_URL}/wp/v2/posts`, {
                params: {
                    slug,
                    _fields: [
                        'title',
                        'content',
                        'modified',
                        'slug',
                        'excerpt'
                    ].join(',')
                }
            }
        );

        const transformedData = response.data.map(transformPost);
        return {...response, data: transformedData};
    } catch (error) {
        console.error('Failed to fetch post:', error);
        throw error;
    }
};

// Fetch and parse meta data
export const getMetaData = async (url) => {
    const slug = url.split('/').filter(Boolean).pop();
    /*    const localData = await getLocalData(slug);

        if (localData && localData.metaInfo) {
            console.log('Using local JSON data for meta info');
            const parsedMeta = parseMetaTags(localData.metaInfo);
            return { data: { head: localData.metaInfo, parsed: parsedMeta } };
        }

        console.log('Local meta data not found, fetching from API');*/
    try {
        // Try to get local data first
        const localData = await getLocalData(slug);
        if (localData && localData.metaInfo) {
            const transformedHead = transformContent(localData.metaInfo);
            const parsedMeta = parseMetaTags(transformedHead);
            return { 
                data: {
                    head: transformedHead,
                    parsed: parsedMeta
                }
            };
        }

        // Fallback to API
        const response = await axios.get(`${BASE_URL}/rankmath/v1/getHead?url=${encodeURIComponent(url)}`);
        const transformedHead = transformContent(response.data.head);
        const parsedMeta = parseMetaTags(transformedHead);

        return {
            data: {
                head: transformedHead,
                parsed: parsedMeta
            }
        };
    } catch (error) {
        console.error('Failed to fetch meta data:', error);
        throw error;
    }
};

// Optimized batch API calls with concurrency control
const batchRequest = async (requests, concurrency = 3) => {
    const results = [];
    for (let i = 0; i < requests.length; i += concurrency) {
        const batch = requests.slice(i, i + concurrency);
        const batchResults = await Promise.all(batch);
        results.push(...batchResults);
    }
    return results;
};

export const getRecentPosts = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/arrify-one/v1/recent-posts`);
        return response;
    } catch (error) {
        console.error('Failed to fetch recent posts:', error);
        throw error;
    }
};


// Fetch paginated blog posts from the new endpoint
export const getPaginatedPosts = async (page = 1) => {
    try {
        const response = await axios.get(`${BASE_URL}/arrify-one/v1/blog-posts`, {
            params: {
                page: page
            }
        });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch paginated blog posts:', error);
        throw error;
    }
};

//Fetch paginated category blog posts from the new endpoint
export const getPaginatedCategoryPosts = async (page = 1, category) => {
    try {
        const response = await axios.get(`${BASE_URL}/arrify-one/v1/blog-posts`, {
            params: {
                page: page,
                category: category
            }
        });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch paginated blog posts:', error);
        throw error;
    }
};

export const getCategories = () => {
    return axios.get(`${BASE_URL}/wp/v2/categories`);
};


// Function to fetch Salesforce sitemap data through CMS
export const getSalesforceRoutes = async () => {
    try {
        const response = await axios.get(`${SF_BASE_URL}/connect/sitemap/`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch Salesforce routes:', error);
        throw error;
    }
};


export const getAllSlugs = async () => {
    try {
        // Fetch posts with modification date and sort by modified date descending
        const response = await axios.get(
            `${BASE_URL}/wp/v2/posts?per_page=100&orderby=modified&order=desc&_fields=slug,modified`
        );

        // Sort data by modified date in descending order
        const sortedData = response.data.sort((a, b) => {
            return new Date(b.modified) - new Date(a.modified);
        });

        // Return only the slugs in the sorted order
        return sortedData.map(post => post.slug);
    } catch (error) {
        console.error('Failed to fetch post slugs:', error);
        throw error;
    }
};

// New function to fetch smart bar data
export const getSmartBarData = async (slug) => {
    try {
        const response = await axios.get(`${BASE_URL}/arrify-one/v2/sb`, {
            params: {
                slug: slug
            }
        });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch smart bar data:', error);
        throw error;
    }
};

export const getCategoriesForSitemap = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/wp/v2/categories`, {
            params: {
                per_page: 100,
                _fields: 'slug'  // Only fetch the slug field
            }
        });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch categories for sitemap:', error);
        throw error;
    }
};

//post feedback data
export const postFeedbackData = async (endpointUrl, data) => {
    console.log('post endpointUrl : ' + endpointUrl);
    try {
        const response = await axios.post(`${SF_CMS_URL}${endpointUrl}`, {
           ...data
        });
        return response.data;
    } catch (error) {
        console.error('Failed to post feedback Data :', error);
        throw error;
    }
};